import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import PageMenu from '../../components/PageMenu/PageMenu';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import { Accordion, AccordionItem } from '../../components/Accordion/Accordion';
import Link from '../../components/Link/Link';
import './style.scss';

type Props = PageProps & WithTranslation;

const AppraisalRebate: React.FC<Props> = (props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t(`appraisalRebate.seo.title`),
    },
    page: {
      title: t(`appraisalRebate.heading`),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.FOREST_GREEN,
        heroImage: {
          fileName: 'hero-appraisal-rebate.png',
          alt: '',
        },
      },
    },
  };

  return (
    <Layout options={layoutOptions} className="AppraisalRebate">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <div className="AppraisalRebate__generic-gap">
              <p>
                <Trans i18nKey="appraisalRebate.body" t={t} />
              </p>
            </div>
            <div className="AppraisalRebate__generic-gap">
              <h2>
                <Trans i18nKey="appraisalRebate.highlights.heading" t={t} />
              </h2>
              <div className="row row-wrap">
                {(t('appraisalRebate.highlights.list', { returnObjects: true }) as {
                  title: string;
                  description: string;
                }[]).map(({ title, description }) => (
                  <div className="column column-30" key={title}>
                    <InfoBlock heading={<h3 className="h5">{title}</h3>}>
                      <Trans>{description}</Trans>
                    </InfoBlock>
                  </div>
                ))}
              </div>
            </div>
            <div className="AppraisalRebate__generic-gap">
              <h2>
                <Trans i18nKey="appraisalRebate.howItWorks.heading" t={t} />
              </h2>
              <p>
                <Trans i18nKey="appraisalRebate.howItWorks.description" t={t} />
              </p>
              <ul>
                {(t('appraisalRebate.howItWorks.list', { returnObjects: true }) as string[]).map(
                  (item, index) => (
                    <li key={`${item.length}-${index}`}>
                      <Trans>{item}</Trans>
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="AppraisalRebate__generic-gap">
              <h2>
                <Trans i18nKey="appraisalRebate.benefits.heading" t={t} />
              </h2>
              <ul>
                {(t('appraisalRebate.benefits.list', { returnObjects: true }) as string[]).map(
                  (item, index) => (
                    <li key={`${item.length}-${index}`}>
                      <Trans>{item}</Trans>
                    </li>
                  )
                )}
              </ul>
              <h3 className="h5">
                <Trans i18nKey="appraisalRebate.benefits.headingTwo" t={t} />
              </h3>
            </div>
            <div className="AppraisalRebate__generic-gap">
              <Accordion allowZeroExpanded>
                <AccordionItem buttonOptions={{ label: t('appraisalRebate.terms.heading') }}>
                  <div className="AppraisalRebate__body-gap">
                    <h4 className="h5">{t('appraisalRebate.terms.headingTwo')}</h4>
                    <p>{t('appraisalRebate.terms.body')}</p>
                  </div>
                  {(t('appraisalRebate.terms.list', {
                    returnObjects: true,
                  }) as { title: string; description: string }[]).map((item) => (
                    <div className="AppraisalRebate__body-gap" key={item.title}>
                      <h4 className="h5">{item.title}</h4>
                      <p>
                        <Trans
                          components={[
                            <Link
                              key="ar-link"
                              to={getPathFromRouteKey(RouteKeys.APPRAISAL_REBATE)}
                            />,
                          ]}
                        >
                          {item.description}
                        </Trans>
                      </p>
                    </div>
                  ))}
                </AccordionItem>
              </Accordion>
            </div>
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={[
                {
                  to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE.MEET_THE_TEAM),
                  textKey: 'pageTitles.meetTheTeam',
                },
                {
                  to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE.BROKER_PORTAL),
                  textKey: 'pageTitles.brokerPortal',
                },
              ]}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.APPRAISAL_REBATE)(AppraisalRebate);
